import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { ServerError } from '@/api/types'
import PasswordField from '@/common/components/Inputs/PasswordField'
import TextField from '@/common/components/Inputs/TextFieldV2'
import { theme } from '@/theme/theme'

type LoginProps = {
  handleLogin: ({ username, password }: { username: string; password: string }) => Promise<void>
  isLoading: boolean
  error: ServerError | null
}

const Login: FC<LoginProps> = ({ handleLogin, isLoading, error }) => {
  const { t } = useTranslation()
  const handleSubmit = async (
    values: { username: string; password: string },
    { setFieldError }: { setFieldError: (field: string, message: string | undefined) => void }
  ) => {
    await handleLogin(values)
    if (error?.response.data.message === 'User has no authorized clients')
      setFieldError('username', 'Your admin is  inactive. Please contact your administrator')
    else setFieldError('password', 'Username or password are incorrect')
  }
  const initialValues = {
    username: '',
    password: '',
  }
  const validationSchema = Yup.object().shape({
    username: Yup.string().email('Invalid email').required('Field required'),
    password: Yup.string().required('Field required'),
  })

  return (
    <Box>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
              <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} paddingBottom={2}>
                {t('login.signIn')}
              </Typography>
              <Box display="flex" flexDirection="column" gap={2} minWidth={568}>
                <TextField required label="Email" name="username" />
                <PasswordField required label={'Password'} name="password" helperText={'dsfsdf'} />
              </Box>
              <Link
                to="/reset-password"
                style={{ color: theme.palette.blue[900], fontWeight: theme.typography.fontWeightSemiBold }}>
                {t('login.forgotPassword')}
              </Link>
              <Box sx={{ width: '100%', paddingRight: 0.5 }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  loading={isLoading || isSubmitting}
                  variant="contained"
                  disabled={!dirty || !isValid}
                  sx={{ width: '100%' }}>
                  {t('login.signIn')}
                </LoadingButton>
              </Box>
              <Typography fontWeight={theme.typography.fontWeightMedium}>
                {t('login.noAccount')}
                <Link
                  to="/signup"
                  style={{
                    color: theme.palette.blue[900],
                    fontWeight: theme.typography.fontWeightSemiBold,
                    textDecoration: 'none',
                  }}>
                  {t('login.signUp')}
                </Link>
              </Typography>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default Login
