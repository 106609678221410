import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Link, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
//@ts-ignore
import * as Yup from 'yup'

import { useMFAVerifyLogin } from '@/api/auth/mfa-verify'
import EmailResendTimer from '@/common/components/EmailResend'
import TextField from '@/common/components/Inputs/TextFieldV2'
import { theme } from '@/theme/theme'
import { useNavigate } from 'react-router-dom'

type MFAProps = { username: string; resendMFA: () => void; MFAType: 'email' | 'app' }

const MFA: FC<MFAProps> = ({ username, resendMFA, MFAType }) => {
  const { t } = useTranslation()
  const { mutateAsync: mfaVerify } = useMFAVerifyLogin()
  const navigateTo = useNavigate()

  const handleSubmit = async (
    values: any,
    { setFieldError }: { setFieldError: (field: string, message: string | undefined) => void }
  ) => {
    try {
      await mfaVerify({ token: values.token })
      navigateTo('./')
    } catch (error) {
      setFieldError('token', error?.response?.data?.message)
    }
  }

  const validationSchema = Yup.object().shape({
    token: Yup.string().required('Field required'),
  })

  const initialValues = { token: '' }

  return (
    <Box>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
              <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold}>
                {t('mfa.title')}
              </Typography>
              <Typography textAlign="center" minWidth={'568px'}>
                {t(MFAType === 'email' ? 'mfa.enterCodeDescriptionEmail' : 'mfa.enterCodeDescriptionApp', { username })}
              </Typography>
              <Box padding={theme.spacing(1)} width={'100%'}>
                <TextField required label="Authentication Code" name="token" />
              </Box>
              {MFAType === 'email' && <EmailResendTimer handleClick={resendMFA} />}
              <Box width="100%" padding={theme.spacing(2, 1)}>
                <LoadingButton
                  size="large"
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                  disabled={!dirty || !isValid}
                  sx={{ width: '100%' }}>
                  {t('mfa.continue')}
                </LoadingButton>
              </Box>
              <Typography fontWeight="fontWeightSemiBold" textAlign="center">
                <Trans
                  i18nKey={MFAType === 'email' ? 'mfa.lostAccesEmail' : 'mfa.lostAccesApp'}
                  components={{
                    1: (
                      <Link
                        href="mailto:support@cywareness.io"
                        color={theme.palette.blue[900]}
                        fontWeight={theme.typography.fontWeightSemiBold}
                      />
                    ),
                  }}
                />
              </Typography>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default MFA
