import { Tooltip } from '@mui/material'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useMembers } from '@/api/members/get'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import Table, { TalbeColumn } from '@/common/components/Table'
import { RowDataItem } from '@/types/common'
import { Member } from '@/types/members'
import { Office } from '@/types/offices'
import { User } from '@/api/users/users'
import { useGroups } from '@/api/groups/get'
import { ALL_DATA_LIMIT } from '@/api/constants'
import { Group } from '@/types/groups'

type UserTableProps = {
  handleEditUser: (user: User) => void
  handleOnboardUser: (ids: string[]) => void
  handleOffboardUser: (ids: string[]) => void
  handleAssignToGroups: (ids: string[]) => void
  handleAssignToOffice: (ids: string[]) => void
  groups?: Group[]
  offices?: Office[]
  queryParams: Record<string, string>
  updateQueryParam: (params: Record<string, string>) => void
}

const UsersTable: FC<UserTableProps> = ({
  handleEditUser,
  handleOffboardUser,
  handleOnboardUser,
  handleAssignToGroups,
  handleAssignToOffice,
  offices,
  queryParams,
  updateQueryParam,
}) => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const { data: queryData, isError, isRefetching, isFetching } = useMembers(queryParams) // TODO add as MembersListQuery
  const { results: members, total } = queryData ?? { total: 0, results: [] }

  // const [pageCount, setPageCount] = useState(0)

  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  // const [pageIndex, setPageIndex] = useState(0)
  const groupsById = useMemo(() => {
    const byId = {} as KeyedGroup
    if (groups) {
      groups.forEach((group) => {
        byId[group._id] = group
      })
    }

    return byId
  }, [groups])

  const tableColumns: TalbeColumn[] = [
    {
      id: 'first_name',
      label: t('users.usersTable.name'),
      sortable: true,
      format: (row: Member) => `${row.first_name} ${row.last_name}`,
    },
    {
      id: 'username',
      sortable: true,
      label: t('users.usersTable.email'),
    },
    {
      id: 'phone_number',
      label: 'Phone Number',
      format: (row: Member) => row.phone_number || '-',
    },
    {
      id: 'groups',
      label: t('users.usersTable.groups'),
      component: (row: Member) => <CellGroups row={row} groupsById={groupsById} />,
    },
    {
      id: 'office_id',
      label: t('users.usersTable.office'),
      component: (row: Member) => <CellOffice row={row} offices={offices} />,
    },
    {
      id: 'awareness_score',
      sortable: true,
      label: t('users.usersTable.score'),
      component: (row: Member) => <HumanizedScore score={row.awareness_score} />,
    },
    {
      id: 'state',
      sortable: true,
      label: t('users.usersTable.status'),
      format: (row: Member) => (row.state === 'active' ? t('users.usersTable.active') : t('users.usersTable.inactive')),
    },
    {
      id: 'actions',
      label: '',
      component: (row: Member) => (
        <CellEditUsers
          row={row}
          handleEditUser={handleEditUser}
          handleOffboardUser={handleOffboardUser}
          handleOnboardUser={handleOnboardUser}
        />
      ),
    },
  ]

  const multiSelectActions = [
    {
      label: 'Activate',
      onClick: (userIds: string[]) => {
        handleOnboardUser(userIds)
      },
    },
    {
      label: 'Deactivate',
      onClick: (userIds: string[]) => {
        handleOffboardUser(userIds)
      },
    },
    {
      label: 'Assign To Groups',
      onClick: (userIds: string[]) => {
        handleAssignToGroups(userIds)
      },
    },
    {
      label: 'Assign to Office',
      onClick: (userIds: string[]) => {
        handleAssignToOffice(userIds)
      },
    },
  ]

  function handleNavigateToUserProfile(row: RowDataItem) {
    navigateTo(`/recipients/users/${row._id}`)
  }

  return (
    <>
      <Table
        loading={isRefetching}
        error={isError}
        data={members}
        total={total}
        inactiveRowCondition={(row) => row.state === 'inactive'}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
        columns={tableColumns}
        selectable
        rowClick={handleNavigateToUserProfile}
        multiSelectBar={{
          selectedCountLable: 'groupProfile.usersTable.user_count',
          actions: multiSelectActions,
        }}
      />
    </>
  )
}

//TODO Move this to TableCell Components
type KeyedGroup = {
  [key: string]: Group
}

type CellGroupProps = {
  row: Member
  groupsById: KeyedGroup
}

type CellOfficeProps = {
  row: Member
  offices?: Office[]
}

type CellEditUsersProps = {
  row: Member
  handleEditUser: (user: User) => void
  handleOffboardUser: (ids: string[]) => void
  handleOnboardUser: (ids: string[]) => void
}

const CellOffice: FC<CellOfficeProps> = ({ row, offices }) => {
  const value = row.office_id
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 'auto',
      }}>
      <div
        style={{
          borderRadius: '4px',
          backgroundColor: '#ADEFF9',
          padding: '2px 4px',
          fontSize: '14px',
        }}>
        {offices?.find((office) => office._id === value)?.name || ''}
      </div>
    </div>
  )
}

const CellGroups: FC<CellGroupProps> = ({ row, groupsById }) => {
  const value = row.member_of
  const groupsByName = []
  if (Array.isArray(value) && value.length > 0) {
    value.forEach((groupId) => {
      const recipientGroup = groupsById[groupId]
      recipientGroup && groupsByName.push(recipientGroup.name)
    })
  }

  return groupsByName.length > 0 ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}>
      <div
        style={{
          borderRadius: '4px',
          backgroundColor: '#ADEFF9',
          padding: '4px 4px',
          fontSize: '14px',
        }}>
        {groupsByName[0]}
      </div>
      {groupsByName.length > 1 ? (
        <Tooltip
          placement={'right'}
          title={groupsByName
            .slice(1)
            .map((group) => group)
            .join(', ')}>
          <div
            style={{
              padding: '3px 8px',
              gap: '10px',
              borderRadius: '20px',
              border: '1px solid #000',
              marginLeft: '5px',
              verticalAlign: 'middle',
            }}>
            {`+${groupsByName.length - 1}`}
          </div>
        </Tooltip>
      ) : null}
    </div>
  ) : (
    '-'
  )
}

const CellEditUsers: FC<CellEditUsersProps> = ({ row, handleEditUser, handleOffboardUser, handleOnboardUser }) => {
  const user = row
  const userId = user._id
  const isActive = user.state === 'active'

  const { t } = useTranslation()

  if (!row) return null

  return (
    <MoreMenu
      items={[
        {
          label: 'users.usersTable.edit',
          onClick: () => {
            handleEditUser(user)
          },
        },
        {
          label: isActive ? t('users.usersTable.deactivate') : t('users.usersTable.activate'),
          textColor: isActive ? '#c40956' : '#007059',
          fontWeight: 'bold',
          onClick: () => {
            isActive ? handleOffboardUser([userId]) : handleOnboardUser([userId])
          },
        },
      ]}
      ariaLabel={'users.usersTable.moreActions'}
    />
  )
}

export default UsersTable
