import { Checkbox, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { filter, intersection } from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'
import SimpleTable from '../Tables/SimpleTable'

import { ALL_DATA_LIMIT } from '@/api/constants'
import { EducationModule } from '@/api/education-modules/modules'
import { useOffices } from '@/api/offices/get'
import { languages } from '@/assets/locales/languages'
import noOfficesAnimation from '@/assets/lottie/suspicion.json'
import { theme } from '@/theme/theme'
import { Targets } from '@/types/campaigns'
import { Office, OfficeWorkingHours } from '@/types/offices'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { byPrefixAndName } from '@awesome.me/kit-39de79544e/icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from '@mui/system'
import { NavLink } from 'react-router-dom'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '../../utils/table-size'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import SimpleSearchBar from '../SimpleSearchBar/SimpleSearchBar'
import StatusFilter from '../StatusFilter/StatusFilter'
import InTableMessageWithAnimation from '../Tables/InTableMessageWithAnimation'
import DistributionSelect from './DistibutionSelect'
import { RecepientsType } from './UserPicker'

type LaunchWizardOfficesTableProps = {
  handleAddOffices: (officeIds: string[], offices: Office[]) => void
  handleRemoveFromList: (id: string | string[]) => void
  handleDistributionTargetsChange: (type: RecepientsType, itemId: string, value: string) => void
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  clientEnforceDomain: boolean
  selectedOffices: string[]
}

const INITIAL_QUERY_FILTERS = {
  name: '',
  status: ['active'],
}

const LaunchWizardOfficesTable: FC<LaunchWizardOfficesTableProps> = ({
  handleAddOffices,
  handleRemoveFromList,
  handleDistributionTargetsChange,
  distributionTargets,
  selectedOffices,
  assets,
  clientEnforceDomain,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { data: officesData } = useOffices({ ...ALL_DATA_LIMIT, for_campaign_creation: true })
  const [queryFilters, setQueryFilters] = useState(INITIAL_QUERY_FILTERS)
  const offices = useMemo(() => {
    return officesData?.results || []
  }, [officesData])
  const isPackage = assets && assets.length > 1
  const onlyAssetsWithPhone = assets?.every((a) =>
    a.vectors?.every((vector) => vector === 'sms' || vector === 'whatsapp')
  )

  const handleFilterChange = useCallback((value: string) => {
    setQueryFilters((prevState) => ({ ...prevState, name: value }))
  }, [])

  const handleStatusChange = useCallback((value: string[]) => {
    setQueryFilters((prevState) => ({ ...prevState, status: value }))
  }, [])

  const data = useMemo(() => {
    offices.forEach((office) => {
      if (office.user_count === 0) {
        office.disabled = true
      }
    })

    return filter(offices, (office: Office) => {
      let matchesName = true
      let matchesStatus = true

      if (queryFilters.name) {
        matchesName = office.name.toLowerCase().includes(queryFilters.name.toLowerCase())
      }

      if (queryFilters.status.length > 0) {
        const isActive = office.has_inactive_member === false
        if (queryFilters.status.includes('active') && isActive) {
          matchesStatus = true
        } else if (queryFilters.status.includes('inactive') && !isActive) {
          matchesStatus = true
        } else {
          matchesStatus = false
        }
      }
      return matchesName && matchesStatus
    })
  }, [offices, queryFilters])

  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE)

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE, pageSize)
  }

  const formatWorkingHours = useCallback(
    ({ days, opening, closing }: OfficeWorkingHours): string => {
      let daysString = ''
      const daysArray = Object.keys(days)
      let i
      for (i = 0; i < daysArray.length; i++) {
        if (days[daysArray[i]]) {
          if (days[daysArray[i]] && !daysString) {
            daysString = t(`createOfficeModal.days.${daysArray[i]}`)
          } else {
            if (daysString && days[daysArray[i - 1]]) {
              if (days[daysArray[i + 1]]) {
              } else {
                daysString = daysString + ` - ${t(`createOfficeModal.days.${daysArray[i]}`)}`
              }
            } else {
              if (daysString && !days[daysArray[i - 1]]) {
                daysString = daysString + `, ${t(`createOfficeModal.days.${daysArray[i]}`)}`
              }
            }
          }
        }
      }

      return daysString
    },
    [t]
  )

  const columns: ColumnInterface[] = useMemo(() => {
    const columns = [
      {
        id: 'selection',
        Header: () => {
          const officeIds = data.map((office: Office) => office._id)

          function handleChange(e) {
            if (e.target.checked) {
              handleAddOffices(officeIds, data)
            } else {
              handleRemoveFromList(officeIds)
            }
          }

          const checked = data.length && intersection(selectedOffices, officeIds).length === data.length
          const isAnyMemberInactive = data.some((office: Office) => office.has_inactive_member)
          const isAnyUnverifiedDomain = data.some(
            (office: Office) => office.has_unverified_domain && clientEnforceDomain
          )

          return (
            <Tooltip
              title={t('launchWizard.officesTable.tooltips.containsWarnings')}
              placement={'right'}
              disableHoverListener={!isAnyMemberInactive && !isAnyUnverifiedDomain}>
              <div>
                <Checkbox onChange={handleChange} checked={checked} />
              </div>
            </Tooltip>
          )
        },
        Cell: ({ row }) => {
          function handleChange(e) {
            if (e.target.checked) {
              handleAddOffices([row.original._id], [row.original])
            } else {
              handleRemoveFromList(row.original._id)
            }
          }

          const office = row.original

          const isDisabled = office.disabled
          const checked = selectedOffices.includes(office._id)

          return (
            <Tooltip
              title={<TableBodyTooltip office={office} />}
              placement={'right'}
              disableHoverListener={!isDisabled}>
              <div>
                <Checkbox checked={checked} onChange={handleChange} disabled={isDisabled} />
              </div>
            </Tooltip>
          )
        },
      },
      {
        Header: t('officesTable.office'),
        accessor: 'name',
        Cell: ({ value, row }) => {
          const hasEmailSimulation = assets && assets.some((asset) => asset?.vectors?.includes('email'))
          const hasWarning =
            (hasEmailSimulation && row.original.has_unverified_domain) || row.original.has_inactive_member
          const office = row.original

          return (
            <div>
              <span>{value}</span>&nbsp;
              {hasWarning ? (
                <Tooltip
                  title={<TableBodyTooltip office={office} />}
                  placement={'right'}
                  disableHoverListener={!hasWarning}>
                  <StyledFontAwesome icon={faTriangleExclamation as IconProp} />
                </Tooltip>
              ) : null}
              {!office.has_member_with_missing_phone && onlyAssetsWithPhone ? (
                <Tooltip
                  title={
                    <Trans
                      i18nKey="launchWizard.officesTable.tooltips.invalidPhoneNumber"
                      components={{ 1: <NavLink to={`/recipients/members`} target="_blank" /> }}
                    />
                  }
                  placement="top">
                  <StyledFontAwesome icon={byPrefixAndName.fak['light-mobile-circle-xmark'] as IconProp} />
                </Tooltip>
              ) : null}
            </div>
          )
        },
      },
      {
        Header: t('officesTable.userCount'),
        accessor: 'user_count',
        Cell: ({ value }) => value,
      },
      {
        Header: t('officesTable.language'),
        accessor: 'language',
        Cell: ({ value }) => languages[value]?.name || '-',
      },
      {
        Header: t('officesTable.workingDays'),
        accessor: 'working_hours',
        disableSortBy: true,
        Cell: ({ value }) => (value.hasOwnProperty('days') ? formatWorkingHours(value) : value), //TODO remove this when working_hours strings are removed from the DB
      },
      {
        Header: t('officesTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => <HumanizedScore score={value} />,
      },
    ]

    if (isPackage) {
      columns.splice(3, 0, {
        Header: t('officesTable.distribution'),
        accessor: 'distribution',
        Cell: ({ row }) => (
          <DistributionSelect
            type="offices"
            onChange={handleDistributionTargetsChange}
            itemId={row.original._id}
            selectedItems={distributionTargets.offices}
            assets={assets}
            disabled={row.original.disabled}
            disableUnverifiedDomain={row.original.has_unverified_domain && clientEnforceDomain}
          />
        ),
      })
    }

    return columns
  }, [t, selectedOffices, data])

  return (
    <>
      <div style={{ marginBottom: theme.spacing(1), display: 'flex' }}>
        <SimpleSearchBar onChange={handleFilterChange} debounceTime={0} />
        <StatusFilter onChange={handleStatusChange} values={queryFilters.status} label="Status" />
      </div>
      <Paper className={classes.root}>
        <SimpleTable<Office>
          stickyHeader
          columns={columns}
          data={data}
          setData={() => {}}
          noResultsContent={<NoResults />}
          enableCheckbox={false}
          customCheckbox={true}
          initialPageSize={defaultRowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

const StyledFontAwesome = styled(FontAwesomeIcon)({
  color: theme.palette.orange[900],
  height: 18,
  display: 'inline-flex',
  alignItems: 'center',
  margin: 'auto',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
})

const NoResults: FC = () => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation animation={noOfficesAnimation}>
      <p>{t('noResults.emptyOffice')}</p>
      <p>
        <Trans i18nKey={'noResults.goToOffice'} components={{ 1: <NavLink to={'/recipients/offices'} /> }} />
      </p>
    </InTableMessageWithAnimation>
  )
}
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        // "&:first-child": {
        //   paddingLeft: 15,
        //   textAlign: "center",
        // },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
      },
    },
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  })
)

const TableBodyTooltip: FC<{ office: Office }> = ({ office }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.tooltipText}>
      <Trans
        i18nKey={
          office.has_inactive_member
            ? 'launchWizard.officesTable.tooltips.inactiveMembers.description'
            : office.has_unverified_domain
            ? 'launchWizard.officesTable.tooltips.unverifiedDomains.description'
            : office.user_count === 0
            ? 'launchWizard.officesTable.tooltips.noMembers.description'
            : ''
        }
        components={{
          1: (
            <Link
              href={
                office.has_inactive_member
                  ? '../../recipients/members'
                  : office.has_unverified_domain
                  ? '../../settings/allowlisting'
                  : office.user_count === 0
                  ? '../../recipients/offices'
                  : ''
              }
              target="_blank"
              className={classes.tooltip}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default LaunchWizardOfficesTable
