import { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useAllReports, ReportObjectMin } from '@/api/reports/reports'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import DateFilter from '@/common/components/Tables/DateFilter'
import { Box, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useQueryParams from '@/common/hooks/useQueryParams'
import Table, { TalbeColumn } from '@/common/components/Table'
import { RowDataItem, SortOrder } from '@/types/common'

type ReportsTableProps = {
  pendingReports?: number
}

const INITIAL_QUERY_FILTERS: {
  startDate: number | null
  endDate: number | null
  limit: number
  skip: number
  sort_by: string
  sort_order: SortOrder
} = {
  startDate: null,
  endDate: null,
  limit: 10,
  skip: 0,
  sort_by: '',
  sort_order: SortOrder.desc,
}

const ReportsTable: FC<ReportsTableProps> = (pendingReports) => {
  const classes = useStyles()
  const navigateTo = useNavigate()
  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)
  const { data: queryData, isLoading, isError } = useAllReports(queryParams)
  const { results: reports, total } = queryData ?? { total: 0, reports: [] }
  const { t } = useTranslation()
  const theme = useTheme()

  const columns: TalbeColumn[] = [
    {
      label: t('responseConsole.table.date'),
      id: 'date_reported',
      sortable: true,
      format: (row: ReportObjectMin) => dayjs.unix(row.date_reported).format('DD/MM/YY'),
    },
    {
      label: t('responseConsole.table.reportedBy'),
      id: 'reported_by',
      sortable: false,
    },
    {
      label: t('responseConsole.table.subject'),
      id: 'subject',
      sortable: false,
    },
    {
      label: t('responseConsole.table.status'),
      id: 'is_phishing',
      sortable: true,
      component: (row: ReportObjectMin) => {
        const textId = row.is_phishing === false ? 'safe' : row.is_phishing ? 'malicious' : 'pending'
        const color =
          row.is_phishing === false
            ? theme.palette.green[900]
            : row.is_phishing
            ? theme.palette.pink[900]
            : theme.palette.blueDianne[900]
        return (
          <>
            <Typography variant={'subtitle2'} color={color}>
              {t(`responseConsole.table.${textId}`)}
            </Typography>
          </>
        )
      },
    },
    {
      label: '',
      id: '',
      sortable: false,
    },
  ]

  const onRowClick = (row: RowDataItem) => {
    navigateTo(`/response-report/${row._id}`)
  }

  const tableTitle = `${t('responseConsole.table.reports')} ${
    pendingReports.pendingReports
      ? t('responseConsole.table.pendingCount', { count: pendingReports.pendingReports })
      : ''
  }`

  return (
    <div className={classes.table}>
      <Box display="flex" justifyContent="space-between" padding={1}>
        <Typography variant={'h5'}>{tableTitle}</Typography>
        <DateFilter queryFilters={queryParams} setQueryFilters={updateQueryParam} />
      </Box>
      <Table
        columns={columns}
        data={reports}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
        loading={isLoading}
        total={total}
        rowClick={onRowClick}
        error={isError}
      />
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'left',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
        },
      },
    },
  })
)

export default ReportsTable
