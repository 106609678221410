import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Box, Collapse, Link, Skeleton, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { QRCodeSVG } from 'qrcode.react'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link as ReactLink, useNavigate } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { useMFASetup } from '@/api/auth/mfa-setup'
import { useMFAVerifySetup } from '@/api/auth/mfa-verify'
import { useCurrentUser } from '@/api/profile/current-user'
import { getErrorMessage } from '@/api/utils/get-error'
import TextField from '@/common/components/Inputs/TextFieldV2'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'

const App: FC = () => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()
  const navigateTo = useNavigate()

  const [isHelpVisible, setIsHelpVisible] = useState(false)
  const [appData, setAppData] = useState({ uri: '', secret: '' })

  const { data: currentUser } = useCurrentUser()

  const initialValues = {
    token: '',
  }

  const validationSchema = Yup.object().shape({
    token: Yup.string().required('Field required'),
  })

  const { mutateAsync: mfaSetup, isPending } = useMFASetup()
  const { mutateAsync: mfaVerify } = useMFAVerifySetup()

  const handleSubmit = async (
    values: { token: string },
    { setFieldError }: { setFieldError: (field: string, message: string | undefined) => void }
  ) => {
    try {
      await mfaVerify({ token: values.token })
      successToast('2FA was successfully enabled')
      if (currentUser?.mfa_enabled) {
        navigateTo('/settings/admins')
      }
    } catch (error) {
      const message = getErrorMessage(error)
      setFieldError('token', message)
    }
  }

  useEffect(() => {
    const setup = async () => {
      try {
        const appData = await mfaSetup({ type: 'app' })
        setAppData(appData)
      } catch (error) {
        errorToast(error)
      }
    }

    setup()
  }, [mfaSetup, setAppData])

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} paddingBottom={2}>
              {t('mfa.title')}
            </Typography>
            <Typography textAlign="center">
              <Trans i18nKey="mfa.setup.app.step1" components={{ 1: <strong /> }} />
            </Typography>
            <Typography textAlign="center">
              <Trans i18nKey="mfa.setup.app.step2" components={{ 1: <strong />, 2: <br /> }} />
            </Typography>
            <Box>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={0}>
                <Box>
                  {isPending ? (
                    <Skeleton variant="rectangular" width={128} height={128} />
                  ) : (
                    <QRCodeSVG value={appData.uri} width={172} />
                  )}
                </Box>
                <Box>
                  <Collapse in={isHelpVisible} easing="out" orientation="horizontal">
                    <Alert
                      severity="info"
                      icon={false}
                      sx={{ textAlign: 'center', fontSize: '12px', marginLeft: 2, maxWidth: 320, maxHeight: 150 }}>
                      <Trans
                        i18nKey="mfa.setup.app.step2hint"
                        values={{ username: currentUser?.username, key: appData.secret }}
                        components={{ 1: <br /> }}
                      />
                    </Alert>
                  </Collapse>
                </Box>
              </Box>
              <Typography
                textAlign="center"
                style={{
                  width: 172,
                  color: theme.palette.blue[900],
                  fontWeight: theme.typography.fontWeightSemiBold,
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsHelpVisible((prev) => !prev)
                }}>
                {t('mfa.setup.app.step2qrTruble')}
              </Typography>
            </Box>
            <Typography textAlign="center">
              <Trans i18nKey="mfa.setup.app.step3" components={{ 1: <strong /> }} />
            </Typography>

            <Box display="flex" flexDirection="column" minWidth={568}>
              <TextField required label="Authentication code" name="token" />
            </Box>

            <Box sx={{ width: '100%' }}>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
                disabled={!dirty || !isValid}
                sx={{ width: '100%' }}>
                {t('mfa.form.submit')}
              </LoadingButton>
            </Box>
            <Typography fontWeight={theme.typography.fontWeightMedium}>
              <Link
                component={ReactLink}
                to="/mfa-setup"
                style={{
                  color: theme.palette.blue[900],
                  fontWeight: theme.typography.fontWeightSemiBold,
                  textDecoration: 'none',
                  display: 'flex',
                  gap: 4,
                  alignItems: 'center',
                }}>
                <FontAwesomeIcon icon={faChevronLeft as IconProp} />
                {t('mfa.backToMethods')}
              </Link>
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default App
