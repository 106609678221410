import { useDynamicGroups } from '@/api/dynamic-groups/get'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import { FC } from 'react'
import DynamicGroupsTable from './DynamicGroupsTable'
import { Box } from '@mui/material'

type DynamicGroupsTabProps = {
  queryParams: Record<string, string>
}

const DynamicGroupsTab: FC<DynamicGroupsTabProps> = ({ queryParams }) => {
  const { data: dynamicGroups = [], isLoading } = useDynamicGroups()
  if (isLoading) {
    return (
      <Box mt={2}>
        <LoadingContainer />
      </Box>
    )
  }

  return <DynamicGroupsTable dynamicGroups={dynamicGroups} queryParams={queryParams} />
}

export default DynamicGroupsTab
