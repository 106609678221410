import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Link, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactLink, useNavigate } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { useCurrentUser } from '@/api/profile/current-user'
import EmailResendTimer from '@/common/components/EmailResend'
import TextField from '@/common/components/Inputs/TextFieldV2'
import { theme } from '@/theme/theme'
import useToast from '@/common/hooks/useToast'
import { useMFASetup } from '@/api/auth/mfa-setup'
import { getErrorMessage } from '@/api/utils/get-error'
import { useMFAVerifySetup } from '@/api/auth/mfa-verify'

const Email: FC = () => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()
  const navigateTo = useNavigate()

  const { data: currentUser } = useCurrentUser()

  // console.log('loginflow', currentUser)
  // if (currentUser?.mfa_enabled) {
  //   //
  // }

  const initialValues = {
    token: '',
  }

  const validationSchema = Yup.object().shape({
    token: Yup.string().required('Field required'),
  })

  const { mutateAsync: mfaSetup } = useMFASetup()
  const { mutateAsync: mfaVerify } = useMFAVerifySetup()

  const handleResend = async () => {
    try {
      await mfaSetup({ type: 'email' })
    } catch (error) {
      errorToast(error)
    }
  }

  const handleSubmit = async (
    values: { token: string },
    { setFieldError }: { setFieldError: (field: string, message: string | undefined) => void }
  ) => {
    try {
      await mfaVerify({ token: values.token })
      successToast('2FA was successfully enabled')
      navigateTo(currentUser?.mfa_enabled ? `/settings/admins/${currentUser.id}` : '/sing-in')
    } catch (error) {
      const message = getErrorMessage(error)
      setFieldError('token', message)
    }
  }

  useEffect(() => {
    const setup = async () => {
      try {
        await mfaSetup({ type: 'email' })
      } catch (error) {
        errorToast(error)
      }
    }

    setup()
  }, [mfaSetup])

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} paddingBottom={2}>
              {t('mfa.title')}
            </Typography>
            <Typography textAlign="center">
              {t('mfa.enterCodeDescriptionEmail', { username: currentUser?.username })}
            </Typography>

            <Box display="flex" flexDirection="column" width="100%">
              <TextField required label={t('mfa.form.code')} name="token" />
            </Box>

            <EmailResendTimer handleClick={handleResend} />

            <Box sx={{ width: '100%' }}>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
                disabled={!dirty || !isValid}
                sx={{ width: '100%' }}>
                {t('mfa.form.submit')}
              </LoadingButton>
            </Box>
            <Typography fontWeight={theme.typography.fontWeightMedium}>
              <Link
                component={ReactLink}
                to="/mfa-setup"
                style={{
                  color: theme.palette.blue[900],
                  fontWeight: theme.typography.fontWeightSemiBold,
                  textDecoration: 'none',
                  display: 'flex',
                  gap: 4,
                  alignItems: 'center',
                }}>
                <FontAwesomeIcon icon={faChevronLeft as IconProp} />
                {t('mfa.backToMethods')}
              </Link>
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default Email
