import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faBadgeCheck,
  faCircleExclamation,
  faMinus,
  faPlus,
  faSquareExclamation,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import dayjs, { Dayjs } from 'dayjs'
import { Form, Formik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { ALL_DATA_LIMIT, API_URL } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import { useAuthenticateToGWS } from '@/api/integrations/authenticate-to-gws'
import useCreateIntegration from '@/api/integrations/create'
import { googleIntegrationType } from '@/api/integrations/integrations'
import useUpdateIntegration from '@/api/integrations/update'
import { useOffices } from '@/api/offices/get'
import AuthenticationIcon from '@/assets/images/Integrations/FormStages/Authentication'
import ConfigurationIcon from '@/assets/images/Integrations/FormStages/Configuration'
import GoogleWorkspaceIcon from '@/assets/images/Integrations/Types/AdGoogleWorkspace.svg'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import Autocomplete from '@/common/components/Inputs/Autocomplete'
import Checkbox from '@/common/components/Inputs/Checkbox'
import Timepicker from '@/common/components/Inputs/Timepicker'
import Stepper from '@/common/components/Stepper'
import Step from '@/common/components/Stepper/Step'
import { theme } from '@/theme/theme'
import { Group } from '@/types/groups'
import { Office } from '@/types/offices'
import InactiveUsersModal from './InactiveUsersModal'
import { FormSection, IntegrationCardType, StyledButtonWrapper } from './IntegrationModal'
import { cronToDayjs } from './IntegrationsUtils'

type Credentials = {
  refresh_token: string
  token: string
}
type GoogleWorkspaceIntegrationnProps = {
  integration: googleIntegrationType | undefined
  integrationTypes: IntegrationCardType[]
  onClose: () => void
}

interface IntegrationFormValues {
  type: string
  authentication: {
    token: string
    refresh_token: string
  }
  configuration: {
    schedule: Dayjs
    userImportStatus: { value: string } | undefined
    defaultUserOffice: Office | null
    departmentGroupMapEnabled: boolean
    groupsMap: Record<string, Group> | undefined
    gWorksapceGroups: string[]
    cywarenessGroups: string[]
  }
}
const GoogleWorkspaceIntegration: FC<GoogleWorkspaceIntegrationnProps> = ({ integration, onClose }) => {
  const [createIntegration] = useCreateIntegration()
  const [updateIntegration] = useUpdateIntegration()
  const { mutateAsync: authenticateToGWS } = useAuthenticateToGWS()
  const [isGoogleAuthInProgress, setIsGoogleAuthInProgress] = useState(false)
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const { data: officesData, isFetching: isFetchingOffices } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const [failed, setFailed] = useState(false)
  const [credentials, setCredentials] = useState<Credentials | undefined>()
  const [inactivePopupOpen, setInactivePopupOpen] = useState(false)
  const { t } = useTranslation()

  const handleMessage = (event: MessageEvent) => {
    if (event.origin === API_URL && event.data.message === 'successful_authorization') {
      setIsGoogleAuthInProgress(false)
      setCredentials(event.data.credentials)
    }
  }

  const handleConnectToGoogleWorkspace = async () => {
    setIsGoogleAuthInProgress(true)
    try {
      const authUrl = await authenticateToGWS()
      const newTab = window.open(authUrl, '_blank')
      if (newTab) {
        newTab.focus()
        const checkTabClosed = setInterval(() => {
          if (newTab.closed) {
            setIsGoogleAuthInProgress(false)
            clearInterval(checkTabClosed)
          }
        }, 1000)
      }
      setFailed(false)
    } catch (error) {
      console.error('Error initiating Google Workspace authentication', error)
      setIsGoogleAuthInProgress(false)
      setFailed(true)
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const initialFormState = {
    type: 'google-workspace',
    configuration: {
      userImportStatus: integration?.configuration.user_import_status || 'active',
      defaultUserOffice:
        offices.filter((office) => office._id === integration?.configuration.default_user_office)[0] || '',
      departmentGroupMapEnabled: Object.keys(integration?.configuration?.groups_map || {}).length > 0 || false,
      schedule: cronToDayjs(integration?.configuration?.schedule),
      gWorksapceGroups:
        integration?.configuration.groups_map && Object.keys(integration.configuration.groups_map).length
          ? Object.keys(integration?.configuration.groups_map)
          : [''],
      cywarenessGroups:
        integration?.configuration.groups_map && Object.values(integration.configuration.groups_map).length
          ? Object.values(integration?.configuration.groups_map)
          : [''],
      groupsMap: {},
    },
  }

  const validationSchema = Yup.object().shape({
    configuration: Yup.object().shape({
      defaultUserOffice: Yup.string().nullable().required(t('settings.integrations.validations.defaultOfficeRequired')),
      schedule: Yup.mixed()
        .nullable()
        .required(t('settings.integrations.validations.importAsRequired'))
        .test('is-valid-time', 'Time is not valid', (value: string) => {
          return dayjs.isDayjs(value) && value.isValid()
        }),
    }),
  })

  const submitForm = async (values: IntegrationFormValues, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true)
    const integrationData = {
      ...values,
      name: 'Google Workspace',
      authentication: {
        token: credentials?.token || '',
        refresh_token: credentials?.refresh_token || '',
      },
      configuration: {
        ...values.configuration,
        schedule: `${values.configuration.schedule.utc().format('m')} ${values.configuration.schedule.format(
          'H'
        )} * * *`,
        userImportStatus: values.configuration.userImportStatus?.value || 'active',
        defaultUserOffice: values.configuration.defaultUserOffice?._id || '',
        groupsMap: values.configuration.departmentGroupMapEnabled
          ? values.configuration.gWorksapceGroups.reduce((acc, key, index) => {
              acc[key] = values.configuration.cywarenessGroups[index]
              return acc
            }, {})
          : undefined,
      },
    }
    if (integration) {
      await updateIntegration({
        id: integration.id,
        integrationData: integrationData,
      })
    } else {
      await createIntegration(integrationData)
    }
    setSubmitting(false)
    onClose()
  }

  const handleSave = async (values: IntegrationFormValues, { setSubmitting }) => {
    if (values.configuration.userImportStatus === 'inactive') {
      setInactivePopupOpen(true)
    } else {
      submitForm(values, setSubmitting)
    }
  }

  return (
    <>
      <Formik onSubmit={handleSave} initialValues={initialFormState} validationSchema={validationSchema}>
        {({ setFieldValue, isSubmitting, values, touched, setFieldTouched, errors, setSubmitting }) => (
          <Form noValidate>
            <InactiveUsersModal
              open={inactivePopupOpen}
              onClose={() => setInactivePopupOpen(false)}
              onConfirm={() => {
                setInactivePopupOpen(false)
                submitForm(values, setSubmitting)
              }}
            />
            <Stepper>
              <Step label={t('settings.integrations.authentication')} icon={AuthenticationIcon} active={true}>
                <FormSection>
                  <Typography>{t('settings.integrations.google.inOrder')}</Typography>
                  <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
                    <StyledGoogleButtonWrapper disabled={isGoogleAuthInProgress || !!credentials || !!integration}>
                      <StyledGoogleButton
                        disabled={isGoogleAuthInProgress || !!credentials || !!integration}
                        variant="outlined"
                        onClick={() => handleConnectToGoogleWorkspace()}
                        startIcon={<img src={GoogleWorkspaceIcon} width={20} />}>
                        {t('settings.integrations.google.connectTo')}
                      </StyledGoogleButton>
                    </StyledGoogleButtonWrapper>
                    <Box whiteSpace="nowrap">
                      {(!!credentials || !!integration) && !isGoogleAuthInProgress && (
                        <Typography>
                          <FontAwesomeIcon
                            icon={faBadgeCheck as IconProp}
                            color={theme.palette.green[500]}
                            width={theme.spacing(3)}
                          />
                          {t('settings.integrations.authenticationVerified')}
                        </Typography>
                      )}
                      {!credentials && !isGoogleAuthInProgress && failed && (
                        <Typography>
                          <FontAwesomeIcon
                            icon={faSquareExclamation as IconProp}
                            color={theme.palette.red[900]}
                            width={theme.spacing(3)}
                          />
                          {t('settings.integrations.authenticationFailed')}
                        </Typography>
                      )}
                      {isGoogleAuthInProgress && (
                        <Typography>
                          <FontAwesomeIcon
                            icon={faCircleExclamation as IconProp}
                            color={theme.palette.blue[500]}
                            width={theme.spacing(3)}
                          />
                          {t('settings.integrations.authenticationInProgress')}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </FormSection>
              </Step>
              <Step
                label={
                  <Box display="flex" alignItems="center">
                    {t('settings.integrations.configurations')}
                    <InfoTooltip title={t('settings.integrations.google.configurationsInfo')}></InfoTooltip>
                  </Box>
                }
                icon={ConfigurationIcon}
                active>
                <FormSection>
                  <Typography>{t('settings.integrations.syncOptions')}</Typography>
                  <Checkbox checked disabled disableFormik name="members" label="Members" />
                  <FormControl fullWidth>
                    <StyledInputLabel id="import-as-select-label">
                      {t('settings.integrations.importAs')}
                    </StyledInputLabel>
                    <StyledSelect
                      required
                      labelId="import-as-select-label"
                      value={values.configuration.userImportStatus}
                      label={t('settings.integrations.importAs')}
                      onChange={(e) => {
                        setFieldValue('configuration.userImportStatus', e.target.value)
                        setFieldTouched('configuration.userImportStatus')
                      }}>
                      <MenuItem key={0} value={'active'}>
                        {t('settings.integrations.active')}
                      </MenuItem>
                      <MenuItem key={1} value={'inactive'}>
                        {t('settings.integrations.inactive')}
                      </MenuItem>
                    </StyledSelect>
                  </FormControl>
                  <Typography fontSize="12px" marginTop={theme.spacing(-1)}>
                    {t('settings.integrations.billed')}{' '}
                  </Typography>
                  <Autocomplete
                    disabled={!offices.length}
                    label={t('settings.integrations.defaultOffice')}
                    placeholder={t('settings.integrations.defaultOffice')}
                    loading={isFetchingOffices}
                    name="configuration.defaultUserOffice"
                    onChange={() => setFieldTouched('configuration.defaultUserOffice')}
                    options={offices || []}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                  />
                  <Typography fontSize="12px" marginTop={theme.spacing(-1)}>
                    {offices.length
                      ? t('settings.integrations.defaultOfficeNote')
                      : t('settings.integrations.linkToCreateNewOfficeNote')}
                  </Typography>
                  {!offices.length && (
                    <a href="/recipients/offices"> {t('settings.integrations.linkToCreateNewOffice')}</a>
                  )}
                  <Checkbox label="Groups" name="configuration.departmentGroupMapEnabled" />
                  {values.configuration.departmentGroupMapEnabled &&
                    values.configuration.gWorksapceGroups.map((group, i) => {
                      return (
                        <Box display="flex" alignItems="center" gap="8px" key={i}>
                          <FormControl fullWidth>
                            <StyledTextField
                              label={t('settings.integrations.google.gWorkspaceGroupEmail')}
                              value={values.configuration.gWorksapceGroups[i]}
                              onChange={(e) => {
                                values.configuration.gWorksapceGroups[i] = e.target.value
                                setFieldValue('configuration.gWorksapceGroups', [
                                  ...values.configuration.gWorksapceGroups,
                                ])
                                setFieldTouched('configuration.gWorksapceGroups')
                              }}
                              placeholder={t('settings.integrations.google.gWorkspaceGroupEmail')}
                            />
                          </FormControl>
                          <FontAwesomeIcon icon={faArrowRight as IconProp} />
                          <FormControl fullWidth>
                            <StyledInputLabel id="cywarenessGroup-select-label">
                              {t('settings.integrations.google.cywarenessGroup')}
                            </StyledInputLabel>
                            <StyledSelect
                              labelId="cywarenessGroup-select-label"
                              value={values.configuration.cywarenessGroups[i]}
                              label={t('settings.integrations.google.cywarenessGroup')}
                              onChange={(e) => {
                                values.configuration.cywarenessGroups[i] = e.target.value as string
                                setFieldValue('configuration.cywarenessGroups', [
                                  ...values.configuration.cywarenessGroups,
                                ])
                                setFieldTouched('configuration.cywarenessGroups')
                              }}>
                              {groups?.map((group) => {
                                return (
                                  <MenuItem key={group._id} value={group._id}>
                                    {group.name}
                                  </MenuItem>
                                )
                              })}
                            </StyledSelect>
                          </FormControl>
                          {i ? (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                values.configuration.gWorksapceGroups.splice(i, 1)
                                setFieldValue('configuration.gWorksapceGroups', [
                                  ...values.configuration.gWorksapceGroups,
                                ])
                                setFieldTouched('configuration.gWorksapceGroups')
                              }}>
                              <FontAwesomeIcon icon={faMinus as IconProp} />
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setFieldValue('configuration.gWorksapceGroups', [
                                  ...values.configuration.gWorksapceGroups,
                                  '',
                                ])
                                setFieldTouched('configuration.gWorksapceGroups')
                              }}>
                              <FontAwesomeIcon icon={faPlus as IconProp} />
                            </Button>
                          )}
                        </Box>
                      )
                    })}
                  <Box>
                    <Typography>{t('settings.integrations.sync')}</Typography>
                    <Box sx={{ display: 'flex', mt: '12px' }}>
                      <Timepicker
                        required
                        name="configuration.schedule"
                        label={t('settings.integrations.syncAt')}
                        onChange={() => {
                          setFieldTouched('configuration.schedule')
                        }}
                      />
                    </Box>
                  </Box>
                </FormSection>
              </Step>
            </Stepper>
            <StyledButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting || (!credentials && !integration) || !Object.keys(touched).length}>
                {!!integration ? t('settings.integrations.update') : t('settings.integrations.save')}
              </Button>
            </StyledButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  )
}

const StyledGoogleButtonWrapper = styled(Box)(({ disabled = false }: { disabled?: boolean }) => ({
  //google colors
  background: disabled ? theme.palette.grey[400] : 'linear-gradient(to right, #ea4335, #fbbc05, #34a853, #4285f4)',
  minWidth: '320px',
  borderRadius: '20px',
}))
const StyledGoogleButton = styled(Button)(() => ({
  margin: '1px',
  border: 'none',
  '&:hover': {
    border: 'none',
  },
  '&:disabled': {
    color: theme.palette.grey[600],
    border: 'none',
    background: theme.palette.grey[300],
  },
}))

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiInputLabel-root[data-shrink="false"]': {
    marginTop: '-9px',
  },
}))

const StyledInputLabel = styled(InputLabel)(() => ({
  fontFamily: 'Montserrat',
  '&.MuiInputLabel-root': {
    marginTop: '-9px',
  },
  '&.MuiFormLabel-filled': {
    marginTop: '0px',
  },
}))
const StyledSelect = styled(Select)(() => ({
  height: '36px',
  '.MuiSelect-select': {
    height: '36px',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
  },
}))
export default GoogleWorkspaceIntegration
