import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CircularProgress, Divider, styled } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/system'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import ProtectedRoute from '@/Routes/ProtectedRoute'
import { useExportUserScores } from '@/api/analytics/export-scores'
import { useClientDetails } from '@/api/client/client'
import { ALL_DATA_LIMIT } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import { useOffices } from '@/api/offices/get'
import { NewOfficeParams, useNewOffice } from '@/api/offices/new-office'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import CreateGroupModal from '@/common/components/CreateGroupModal/CreateGroupModal'
import CreateOfficeModal from '@/common/components/CreateOfficeModal/CreateOfficeModal'
import CreateUserModal from '@/common/components/CreateUserModal/CreateUserModal'
import LanguageFilter from '@/common/components/LanguageFilter/LanguageFilter'
import SimpleSearchBar from '@/common/components/SimpleSearchBar/SimpleSearchBar'
import StatusFilter from '@/common/components/StatusFilter/StatusFilter'
import MainTab from '@/common/components/Tabs/MainTab'
import MainTabPage from '@/common/components/Tabs/MainTabPage'
import MainTabs from '@/common/components/Tabs/MainTabs'
import MainTabsWrapper from '@/common/components/Tabs/MainTabsWrapper'
import TimezoneFilter from '@/common/components/TimezoneFilter/TimezoneFilter'
import UserTagsFilter from '@/common/components/UserTagsFilter/UserTagsFilter'
import useQueryParams from '@/common/hooks/useQueryParams'
import useToast from '@/common/hooks/useToast'
import { downloadFromUrl } from '@/common/utils/download-url'
import { indexToTab, tabToIndex } from '@/common/utils/tab-utils'
import BulkUserUploadModal from '@/pages/Users/UsersTab/BulkUserUploadModal'
import { theme } from '@/theme/theme'
import { faAtom, faBriefcase, faCaretDown, faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons'
import ActiveDirectory from './ActiveDirectory/ActiveDirectory'
import DynamicGroupsTab from './DynamicGroupsTab'
import GroupsTab from './GroupsTab/GroupsTab'
import OfficesTab from './OfficesTab/OfficesTab'
import UsersTab from './UsersTab/UsersTab'

const tabIndexMap = {
  '/recipients/members': 0,
  '/recipients/groups': 1,
  '/recipients/dynamic-groups': 2,
  '/recipients/offices': 3,
}

const INITIAL_QUERY_FILTERS: {
  search: string
  status: string[]
  offices: string[]
  groups: string[]
  awareness_score: string[]
  languages: string[]
  timezones: string[]
  skip: number
  limit: number
  sort_by: string
  sort_order: string
} = {
  search: '',
  status: [],
  offices: [],
  groups: [],
  awareness_score: [],
  languages: [],
  timezones: [],
  skip: 0,
  limit: 10,
  sort_by: '',
  sort_order: 'desc',
}

const Users: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const location = useLocation()

  const { data: clientDetails } = useClientDetails()

  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)
  const [searchValue, setSearchValue] = useState(INITIAL_QUERY_FILTERS.search)
  const [anchorEl, setAnchorEl] = useState(null)
  const addMembersOpen = Boolean(anchorEl)

  const [officeLanguages, setOfficeLanguages] = useState<string[]>([])
  const [officeTimezones, setOfficeTimezones] = useState<string[]>([])

  const { pathname } = useLocation()
  const [currentTab, setCurrentTab] = useState(tabToIndex(tabIndexMap, pathname))
  const isOnActiveDirectory = pathname === '/recipients/active-directory'

  const handleAddMembersClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseAddMembers = () => {
    setAnchorEl(null)
  }

  const handleTabChange = useCallback(
    (e, index) => {
      setCurrentTab(index)
      navigateTo(indexToTab(tabIndexMap, index))
    },
    [setCurrentTab, navigateTo]
  )

  useEffect(() => {
    if (tabToIndex(tabIndexMap, pathname) !== currentTab) {
      handleTabChange(null, tabToIndex(tabIndexMap, pathname))
    }
  }, [currentTab, handleTabChange, pathname])

  useEffect(() => {
    if (location?.state?.addUser) {
      setUserModalIsOpen(true)
    }
  }, [location, location.state])

  const { data: officesData } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const [exportCsv, { isLoading: isExportingCsv }] = useExportUserScores()
  const [groupModalIsOpen, setGroupModalIsOpen] = useState(false)
  const [userModalIsOpen, setUserModalIsOpen] = useState(false)
  const [userBulkModalIsOpen, setUserBulkModalIsOpen] = useState(false)

  const [createNewOffice] = useNewOffice()
  const [officeModalIsOpen, setOfficeModalIsOpen] = useState(false)

  const { successToast, errorToast } = useToast()

  async function handleCreateNewOffice(values: NewOfficeParams) {
    try {
      await createNewOffice(values)
      successToast(t('users.newOfficeSuccess'))
    } catch (e) {
      const errorMessage =
        // @ts-ignore
        e.response.data.message ?? t('users.errors.faliedNewOffice')
      errorToast(errorMessage)
      throw e
    }
  }

  async function handleExportCsv() {
    try {
      const fileUrl = await exportCsv()
      if (fileUrl) {
        downloadFromUrl(fileUrl)
      } else {
        throw new Error()
      }
    } catch (e) {
      const errorMessage =
        // @ts-ignore
        e?.response?.data?.message ?? t('users.errors.failedToExport')
      errorToast(errorMessage)
    }
  }

  const handleSearch = (value: string) => {
    setSearchValue(value)

    updateQueryParam({ search: value })
  }

  const handleStatusChange = (newStatus: string[]) => {
    updateQueryParam({ status: newStatus })
  }

  function handleLanguageClick(languages: string[]) {
    updateQueryParam({ languages })
  }

  function handleTimezoneClick(timezones: string[]) {
    updateQueryParam({ timezones })
  }

  useEffect(() => {
    const languages = offices.map((office) => office.language)
    const uniqueLanguages = new Set(languages)
    setOfficeLanguages(Array.from(uniqueLanguages))

    const timezones = offices.map((office) => office.time_zone)
    const uniqueTimezones = new Set(timezones)
    setOfficeTimezones(Array.from(uniqueTimezones))
  }, [offices])

  function handleTagClick(tag: any) {
    const groupTags = [...queryParams.groups]
    const officeTags = [...queryParams.offices]
    const scoreTags = [...queryParams.awareness_score]

    if (tag.type === 'group') {
      if (queryParams.groups.includes(tag.id)) {
        const index = groupTags.indexOf(tag.id)
        groupTags.splice(index, 1)
      } else {
        groupTags.push(tag.id)
      }
    } else if (tag.type === 'office') {
      if (queryParams.offices.includes(tag.id)) {
        const index = officeTags.indexOf(tag.id)
        officeTags.splice(index, 1)
      } else {
        officeTags.push(tag.id)
      }
    } else if (tag.type === 'score') {
      if (queryParams.awareness_score.includes(tag.id)) {
        const index = scoreTags.indexOf(tag.id)
        scoreTags.splice(index, 1)
      } else {
        scoreTags.push(tag.id)
      }
    }
    updateQueryParam({ groups: groupTags, offices: officeTags, awareness_score: scoreTags })
  }

  return (
    <div style={{ padding: '0px 18px' }}>
      <CreateUserModal
        open={userModalIsOpen}
        onClose={() => {}}
        offices={offices}
        groups={groups || []}
        setOpen={setUserModalIsOpen}
      />
      <BulkUserUploadModal open={userBulkModalIsOpen} onClose={() => setUserBulkModalIsOpen(false)} />
      <CreateGroupModal open={groupModalIsOpen} setOpen={setGroupModalIsOpen} />
      <CreateOfficeModal
        saveOfficeEdit={() => {}}
        open={officeModalIsOpen}
        setOpen={setOfficeModalIsOpen}
        createNewOffice={handleCreateNewOffice}
        buttonClass={classes.button}
        onClose={() => {}}
      />
      <div className={classes.flexContainer}>
        <div className={classes.flex}>
          <div className={classes.searchContainer}>
            <SimpleSearchBar
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value)
                handleSearch(value)
              }}
              debounceTime={300}
            />
          </div>
          <div>
            <div className={classes.flex}>
              {currentTab === 0 ? (
                <StatusFilter values={queryParams.status} onChange={handleStatusChange} label={'Status'} />
              ) : null}
              {currentTab === 0 || currentTab === 3 ? (
                <>
                  <TimezoneFilter
                    timezones={officeTimezones}
                    selectedTimezones={queryParams.timezones}
                    onChange={handleTimezoneClick}
                    label={'Timezones'}
                  />
                  <LanguageFilter
                    languages={officeLanguages}
                    selectedLanguages={queryParams.languages}
                    onChange={handleLanguageClick}
                    label={'Languages'}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <UserTagsFilter
        selectedTags={[...queryParams.groups, ...queryParams.offices, ...queryParams.awareness_score]}
        onTagClick={handleTagClick}
        offices={currentTab !== 3 ? offices : []}
        groups={currentTab !== 1 ? groups : []}
      />
      <Divider className={classes.divider} />
      <StyledTopBarBox>
        <Box minWidth="fit-content">
          <CustomBreadcrumbs />
        </Box>
        {currentTab === 0 ? (
          <Box display={'flex'}>
            {clientDetails?.ad_enabled ? (
              <Button
                onClick={() => {
                  navigateTo('/recipients/active-directory')
                }}
                variant="outlined">
                {t('users.activeDirectory.activeDirectory')}
              </Button>
            ) : null}
            <Button
              onClick={handleAddMembersClick}
              aria-controls={addMembersOpen ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={addMembersOpen ? 'true' : undefined}
              variant="outlined"
              endIcon={<FontAwesomeIcon icon={faCaretDown as IconProp} />}>
              {t('users.newMember')}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={addMembersOpen}
              onClose={handleCloseAddMembers}
              className={classes.menuWrapper}>
              <MenuItem className={classes.menuItem}>
                <Button sx={{ fontWeight: 'regular' }} onClick={() => setUserModalIsOpen(true)}>
                  {t('users.newMember')}
                </Button>
                {/* <BulkUserUploadModal1 offices={offices?.array ?? []} groups={groups || []} /> */}
              </MenuItem>

              <MenuItem className={classes.menuItem}>
                <Button sx={{ fontWeight: 'regular' }} onClick={() => setUserBulkModalIsOpen(true)}>
                  Bulk Upload Members
                </Button>
              </MenuItem>
            </Menu>
            <Button onClick={handleExportCsv} variant="outlined">
              {isExportingCsv ? <CircularProgress color={'primary'} size={20} /> : 'Export Members Scores'}
            </Button>
          </Box>
        ) : currentTab === 1 ? (
          <Button onClick={() => setGroupModalIsOpen(true)} variant="outlined">
            {isExportingCsv ? <CircularProgress color={'primary'} size={20} /> : 'New Group'}
          </Button>
        ) : currentTab === 3 ? (
          <Button onClick={() => setOfficeModalIsOpen(true)} variant="outlined">
            {isExportingCsv ? <CircularProgress color={'primary'} size={20} /> : 'New Office'}
          </Button>
        ) : null}
      </StyledTopBarBox>

      {!isOnActiveDirectory ? (
        <div className={classes.root}>
          <MainTabsWrapper>
            <MainTabs value={currentTab} onChange={handleTabChange}>
              <MainTab
                disableRipple
                icon={
                  <Box>
                    <FontAwesomeIcon icon={faUser as IconProp} fontSize={18} />
                  </Box>
                }
                iconPosition="start"
                label={'Members'}
                onClick={() => navigateTo('/recipients/members')}
              />
              <MainTab
                disableRipple
                icon={
                  <Box>
                    <FontAwesomeIcon icon={faUserGroup as IconProp} fontSize={18} />
                  </Box>
                }
                iconPosition="start"
                label={t('users.groups')}
                onClick={() => {
                  navigateTo('/recipients/groups'), updateQueryParam({ sort_by: '', sort_order: 'desc' })
                }}
              />
              <MainTab
                disableRipple
                icon={
                  <Box>
                    <FontAwesomeIcon icon={faAtom as IconProp} fontSize={18} color={theme.palette.purple[500]} />
                  </Box>
                }
                iconPosition="start"
                label={t('users.dynamicGroups')}
                onClick={() => navigateTo('/recipients/dynamic-groups')}
              />
              <MainTab
                disableRipple
                icon={
                  <Box>
                    <FontAwesomeIcon icon={faBriefcase as IconProp} fontSize={18} />
                  </Box>
                }
                iconPosition="start"
                label={t('users.offices')}
                onClick={() => {
                  navigateTo('/recipients/offices'), updateQueryParam({ sort_by: 'name', sort_order: 'desc' })
                }}
              />
            </MainTabs>
          </MainTabsWrapper>
        </div>
      ) : null}
      <MainTabPage className={classes.mainTabPage}>
        <Routes>
          <Route
            path="members"
            element={
              <ProtectedRoute>
                <UsersTab queryParams={queryParams} updateQueryParam={updateQueryParam} />
              </ProtectedRoute>
            }
          />
          <Route
            path="groups"
            element={
              <ProtectedRoute>
                <GroupsTab queryParams={queryParams} updateQueryParam={updateQueryParam} />
              </ProtectedRoute>
            }
          />
          <Route
            path="dynamic-groups"
            element={
              <ProtectedRoute>
                <DynamicGroupsTab queryParams={queryParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path="offices"
            element={
              <ProtectedRoute>
                <OfficesTab queryParams={queryParams} updateQueryParam={updateQueryParam} />
              </ProtectedRoute>
            }
          />
          <Route
            path="active-directory"
            element={
              <ProtectedRoute>
                <ActiveDirectory />
              </ProtectedRoute>
            }
          />
        </Routes>
      </MainTabPage>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      overflow: 'visible',
      justifyContent: 'space-between',
    },
    flexContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      marginLeft: theme.spacing(1),
    },
    exportButton: {
      minWidth: 160,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      margin: 'auto',
      width: '98%',
    },
    searchContainer: {
      width: '100%',
      margin: theme.spacing(2, 0, 2, 0),
    },
    searchInput: {
      border: `1px solid rgba(0, 0, 0, 0.28)`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 2),
    },
    menuWrapper: {
      marginTop: '10px',
    },
    menuItem: {
      display: 'flex',
      padding: '0px',
      flexDirection: 'column',
      width: '230px',
      alignItems: 'flex-start',
      '&:hover': {
        background: 'none',
      },
    },
    divider: {
      margin: '18px',
    },
    mainTabPage: {
      padding: '10px 0px 60px 0px',
      minWidth: '560px',
    },
  })
)

const StyledTopBarBox = styled(Box)(() => ({
  margin: 'auto',
  display: 'flex',
  marginTop: theme.spacing(2),
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}))

export default Users
