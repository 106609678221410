import { useTheme } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useGroups } from '@/api/groups/get'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import Table, { TalbeColumn } from '@/common/components/Table'
import Tags from '@/common/components/Tags/Tags'
import { Group } from '@/types/groups'

type GroupTableProps = {
  handleDeleteGroup: (_id: string) => void
  queryParams: Record<string, string>
  updateQueryParam: (params: Record<string, string>) => void
}

const GroupsTable: FC<GroupTableProps> = ({ handleDeleteGroup, queryParams, updateQueryParam }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigateTo = useNavigate()
  const { data: queryData, isError, isFetching } = useGroups(queryParams)
  const { results: newGroups, total } = queryData ?? { total: 0, results: [] }

  const tableColumns: TalbeColumn[] = [
    {
      id: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      id: 'user_count',
      label: 'No. Of Members',
      sortable: true,
    },
    {
      id: 'managers',
      label: 'Group Managers',
      component: (row: Group) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            {(row.managers || []).length > 0 ? (
              // TODO: manager type
              <Tags
                useChips={false}
                tags={(row.managers || []).map((manager: any) => ({
                  label: `${manager.first_name} ${manager.last_name}` || '-',
                  color: theme.palette.success.light,
                }))}
                limit={1}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },

    {
      id: 'awareness_score',
      label: t('users.groupsTable.score'),
      sortable: true,
      component: (row: Group) => {
        return <HumanizedScore score={row.awareness_score} />
      },
    },
    {
      id: 'actions',
      label: '',
      component: (row) => {
        const groupId = row._id
        return (
          <MoreMenu
            items={[
              {
                label: 'users.groupsTable.remove',
                textColor: theme.palette.error.main,
                onClick: () => handleDeleteGroup(groupId),
              },
            ]}
            ariaLabel={'users.groupsTable.moreActions'}
          />
        )
      },
    },
  ]

  function handleNavigateToGroupProfile(id: string) {
    navigateTo(`/recipients/groups/${id}`)
  }

  return (
    <Table
      loading={isFetching}
      error={isError}
      data={newGroups}
      total={total}
      inactiveRowCondition={(row) => row.state === 'inactive'}
      queryParams={queryParams}
      updateQueryParam={updateQueryParam}
      columns={tableColumns}
      rowClick={(row) => {
        handleNavigateToGroupProfile(row._id)
      }}
    />
  )
}
export default GroupsTable
