import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useDisableMFA } from '@/api/admins/disable-mfa'
import { CurrentUser } from '@/api/profile/current-user'
import questionImg from '@/assets/images/popup/question.svg'
import Popup from '@/common/components/Popup'
import useToast from '@/common/hooks/useToast'
import { StyledTitle } from '.'

const AdminProfileMFA: FC<{ user: CurrentUser | undefined }> = ({ user }) => {
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(user?.mfa_enabled || false)
  const [isDisableMFAPopupOpen, setIsDisableMFAPopupOpen] = useState(false)

  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const { successToast, errorToast } = useToast()
  const { mutateAsync: disableMFA } = useDisableMFA()

  const handleMfaChange = async (value: boolean) => {
    if (value === false) {
      if (user?.mfa_enabled) {
        setIsDisableMFAPopupOpen(true)
      } else {
        setMfaEnabled(false)
      }
    } else {
      setMfaEnabled(true)
    }
  }

  const handleDisableMFA = async () => {
    try {
      await disableMFA()
      setMfaEnabled(false)
      setIsDisableMFAPopupOpen(false)
      successToast(t(`settings.admins.profile.mfaDisbleSuccessToast`))
    } catch {
      errorToast(t(`settings.admins.profile.mfaDisableErrorToast`))
    }
  }

  return (
    <>
      <StyledTitle>{t(`settings.admins.profile.security`)}</StyledTitle>
      <Box display="flex" flexDirection="column" gap={2} p={2}>
        <Typography>{t(`settings.admins.profile.mfaInfo`)}</Typography>
        <Box display="flex" width="500px" justifyContent="space-between" fontWeight="fontWeightSemiBold">
          {t(`settings.admins.profile.twoFactorAuth`)}
          <ButtonGroup aria-label="Basic button group" fullWidth sx={{ maxWidth: '200px' }}>
            <Button
              variant={mfaEnabled ? 'outlined' : 'contained'}
              onClick={() => {
                handleMfaChange(false)
              }}>
              {t(`settings.admins.profile.off`)}
            </Button>
            <Button
              variant={mfaEnabled ? 'contained' : 'outlined'}
              onClick={() => {
                handleMfaChange(true)
              }}>
              {t(`settings.admins.profile.on`)}
            </Button>
          </ButtonGroup>
        </Box>
        <Box display="flex" width="500px" justifyContent="space-between" fontWeight="fontWeightSemiBold">
          {t(`settings.admins.profile.authenticationMethod`)}
          {user?.mfa_type ? (
            <ButtonGroup aria-label="Basic button group" fullWidth sx={{ maxWidth: '200px' }}>
              <Button
                variant={user?.mfa_type === 'app' ? 'contained' : 'outlined'}
                onClick={() => navigateTo('/mfa-setup/app')}>
                {t(`settings.admins.profile.mobile`)}
              </Button>
              <Button
                variant={user?.mfa_type === 'email' ? 'contained' : 'outlined'}
                onClick={() => navigateTo('/mfa-setup/email')}>
                {t(`settings.admins.profile.email`)}
              </Button>
            </ButtonGroup>
          ) : (
            <Button
              disabled={!mfaEnabled}
              variant={mfaEnabled ? 'outlined' : 'contained'}
              fullWidth
              sx={{ maxWidth: '200px' }}
              onClick={() => navigateTo('/mfa-setup')}>
              {t(`settings.admins.profile.configure`)}
            </Button>
          )}
        </Box>
      </Box>

      <Popup
        open={isDisableMFAPopupOpen}
        onClose={() => {
          setIsDisableMFAPopupOpen(false)
        }}
        onConfirm={handleDisableMFA}
        buttonLabels={{
          confirm: t('settings.admins.profile.disableMFAPopup.confirmButton'),
          cancel: t('settings.admins.profile.disableMFAPopup.cancelButton'),
        }}
        icon={questionImg}>
        <h4>{t('settings.admins.profile.disableMFAPopup.title')}</h4>
        <p>{t('settings.admins.profile.disableMFAPopup.message1')}</p>
        <p>
          <Trans i18nKey="settings.admins.profile.disableMFAPopup.message2" components={{ 1: <b /> }} />
        </p>
      </Popup>
    </>
  )
}

export default AdminProfileMFA
