import { palette } from '@/theme/palette'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment } from '@mui/material'
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import { FieldHookConfig, useField } from 'formik'
import { ChangeEvent, FC, FocusEvent } from 'react'
import HelperTextComponent from './HelperTextComponent'

//NOTE: Rename this to v2.tsx file to index after refactorin all the places where current TextField is used
export const MuiTextFieldSX = {
  // marding for the helper text
  // TODO: consider moving the class name to theme variant/ styledComponent prop
  '&.validate': {
    marginRight: '12px',
  },
  width: '100%',
  height: '68px',
  '& .MuiOutlinedInput-root': {
    height: '56px',
    background: palette.white,
    borderRadius: '10px',
    '& legend': {
      width: 0,
    },
    '&.Mui-focused fieldset': {
      border: '1px solid',
    },
    '& .MuiInputAdornment-root': {
      fontSize: '14px',
      marginRight: '-4px',
      paddingRight: '0px',
    },

    '&.MuiInputBase-colorSecondary': {
      background: palette.blueGray[800],
      color: palette.white,
      fieldset: {
        border: `1px solid ${palette.cyan[500]}`,
      },
      '& .MuiInputAdornment-root': {
        color: palette.white,
      },
    },
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
  '& .MuiInputLabel-root[data-shrink="false"]': {
    fontSize: '16px',
    color: palette.grey[500],
  },
  '& .MuiInputLabel-root.MuiFormLabel-colorSecondary[data-shrink="false"]': {
    color: palette.grey[300],
  },

  '& .MuiInputLabel-root.Mui-disabled': {
    color: palette.grey[600],
    background: palette.grey[400],
  },

  '& .MuiInputLabel-root[data-shrink="true"]': {
    marginTop: '1px',
    padding: '2px 8px 2px 8px',
    fontSize: '12px',
    background: palette.white,
    borderRadius: '5px',
    color: palette.blueDianne[900],
  },
  '& .MuiInputLabel-root.MuiFormLabel-colorSecondary[data-shrink="true"]': {
    background: palette.blueGray[800],
    color: palette.white,
  },

  '& .MuiOutlinedInput-root.Mui-disabled': {
    background: palette.grey[300],
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: palette.grey[500], // Keep the label color unchanged on error
  },
}

export const MuiLockedTextFieldSX = {
  '& .MuiInputLabel-root.Mui-disabled': {
    color: palette.blueDianne[900] + '!important',
    background: palette.grey[200],
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input': {
    color: palette.blueDianne[900] + '!important',
    '-webkit-text-fill-color': palette.blueDianne[900] + '!important',
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    color: palette.blueDianne[900] + '!important',
    background: palette.grey[200],
  },
}

interface TextFieldProps
  extends Omit<MuiTextFieldProps, 'name' | 'value' | 'error' | 'onChange' | 'onBlur'>,
    Pick<FieldHookConfig<string>, 'name'> {
  disableFormik?: boolean
  label: MuiTextFieldProps['label']
  value?: MuiTextFieldProps['value']
  isLocked?: boolean
  onChange?: MuiTextFieldProps['onChange']
  onBlur?: MuiTextFieldProps['onBlur']
}

const TextField: FC<TextFieldProps> = ({
  name,
  label,
  InputProps,
  InputLabelProps,
  disableFormik = false,
  isLocked = false,
  onChange,
  onBlur,
  ...otherProps
}) => {
  const [field, meta] = useField(name)

  const combinedInputProps = isLocked
    ? {
        endAdornment: (
          <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
            <FontAwesomeIcon icon={faLockKeyhole as IconProp} color={palette.blueDianne[900]} />
          </InputAdornment>
        ),
      }
    : {}

  const configTextField: MuiTextFieldProps = {
    ...otherProps,
    fullWidth: true,
    label: label,
    // placeholder: label as string,
    // InputLabelProps: combinedInputLabelProps,
    InputProps: combinedInputProps as any,
  }

  if (!disableFormik) {
    // Merge Formik-related props if Formik is enabled
    Object.assign(configTextField, {
      ...field,
      className: disableFormik ? '' : 'validate',
      error: meta.touched && Boolean(meta.error),
      helperText: <HelperTextComponent hasError={meta.touched && Boolean(meta.error)} helperText={meta.error} />,
      onChange: onChange
        ? (event: ChangeEvent<HTMLInputElement>) => {
            field.onChange(event)
            onChange(event)
          }
        : field.onChange,
      onBlur: onBlur
        ? (event: FocusEvent<HTMLInputElement>) => {
            field.onBlur(event)
            onBlur(event)
          }
        : field.onBlur,
    })
  } else {
    // Attach provided onChange and onBlur handlers for non-Formik usage
    if (onChange) configTextField.onChange = onChange
    if (onBlur) configTextField.onBlur = onBlur
  }

  return <MuiTextField {...configTextField} sx={{ ...MuiTextFieldSX, ...(isLocked && MuiLockedTextFieldSX) }} />
}

export default TextField
