import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAtom, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { filter } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ColumnInterface } from 'react-table'

import { US_DATE_TIME_WITH_MONTH_NAME } from '@/api/constants'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import SimpleTable from '@/common/components/Tables/SimpleTable'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '@/common/utils/table-size'
import { theme } from '@/theme/theme'
import { DynamicGroup } from '@/types/dynamicGroups'

type DynamicGroupsTableProps = {
  dynamicGroups: DynamicGroup[]
  queryParams: Record<string, string>
}

const DynamicGroupsTable: FC<DynamicGroupsTableProps> = ({ dynamicGroups, queryParams }) => {
  const navigateTo = useNavigate()
  const { t } = useTranslation()
  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE)
  const [data, setData] = useState<DynamicGroup[]>(dynamicGroups)

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE, pageSize)
  }

  function handleNavigateToGroupProfile({ original }) {
    navigateTo(`/recipients/dynamic-groups/${original.id}`)
  }

  useEffect(() => {
    let filteredGroups = [...dynamicGroups]
    if (queryParams.search) {
      filteredGroups = filter(dynamicGroups, (group: DynamicGroup) => {
        return queryParams.search ? group.name.toLowerCase().includes(queryParams.search.toLowerCase()) : true
      })
    }
    setData(filteredGroups)
  }, [dynamicGroups, queryParams])

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('dynamicGroups.groupType'),
        accessor: 'name',
        Cell: ({ row }) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              <FontAwesomeIcon icon={faAtom as IconProp} color={theme.palette.purple[500]} fontSize={18} />{' '}
              {row.original.name}
              <Tooltip title={row.original.description} placement="top-start">
                <FontAwesomeIcon icon={faInfoCircle as IconProp} />
              </Tooltip>
            </Box>
          )
        },
      },
      {
        Header: t('dynamicGroups.totalMembers'),
        accessor: 'count',
      },

      {
        Header: t('dynamicGroups.lastCampaignSent'),
        accessor: 'campaign_last_sent_at',
        Cell: ({ value }) => (value ? dayjs.utc(value).format(US_DATE_TIME_WITH_MONTH_NAME) : '-'),
      },
      // {
      //   Header: t('dynamicGroups.lastUpdated'),
      //   accessor: 'updated_at',
      //   Cell: ({ value }) => dayjs.utc(value).format(US_DATE_TIME_WITH_MONTH_NAME),
      // },
      {
        Header: t('dynamicGroups.score'),
        accessor: 'awareness_score',
        Cell: ({ value }: { value: number }) => <HumanizedScore score={value} />,
      },
    ],
    [t]
  )

  return (
    <Box>
      {/* {dynamicGroups.length > 0 && (
        <Typography margin={2} fontWeight={theme.typography.fontWeightSemiBold}>
          {t('dynamicGroups.lastSyncedOn')}
          {dayjs.utc(dynamicGroups[0].updated_at).format(US_DATE_TIME_WITH_MONTH_NAME)}
        </Typography>
      )} */}
      <SimpleTable
        columns={columns}
        enableCheckbox={false}
        data={data}
        setData={() => {}}
        noResultsContent={<></>}
        onRowClick={handleNavigateToGroupProfile}
        initialPageSize={defaultRowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  )
}
export default DynamicGroupsTable
