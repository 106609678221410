export enum OrganizationAccountType {
  client = 'client',
  direct_reseller = 'direct_reseller',
  indirect_reseller = 'indirect_reseller',
  distributor = 'distributor',
}

export enum OrganizationState {
  trial = 'trial',
  subscribed = 'subscribed',
  suspended = 'suspended',
  archived = 'archived',
}

type OrganizationDomain = {
  domain: string
  verified: boolean
}

export type OrganizationListItem = {
  _id: string
  parent_id: string
  name: string
  logo: string
  awareness_score: number
  type: OrganizationAccountType
  origin_country: string
  is_admin_authorized: boolean
  domain: OrganizationDomain
  active_members: number
  total_active_members: number
  number_of_children: number
  created_at: string
}

export type OrganizationRequest = {
  parent_id: string
  name: string
  admin_email?: string
  type: OrganizationAccountType
  domain?: string
  admin_name?: string
  allow_support_admin_access?: boolean
}

export type OrganizationAcceptInviteRequest = {
  token: string
}

type OrganizationUpdateParams = {
  name: string
  domain?: string
  admin_email?: string
  type?: OrganizationAccountType
}

export type OrganizationUpdateRequest = {
  id: string
  params: OrganizationUpdateParams
}
